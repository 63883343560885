export const ERROR_FRAGMENT = `
  message
  field
  code
`;

export const ADDRESS_FRAGMENT = `
  street
  street2
  zipCode
  city
  vmJobNumber
  vmRoNumber
`;

export const ASSET_IMAGE_FRAGMENT = `
  url
  id
  default
  assetId
`;

export const WAREHOUSE_LOCATION_IMAGE_FRAGMENT = `
  url
  id
  default
`;

export const KIT_IMAGE_FRAGMENT = `
  url
  id
  default
  assetId
`;

export const USER_FRAGMENT = `
  id
  email
  name
  phoneNumber
  uid
  token
  invitationAccepted
  role
  clientIds
  captureTableSettings
  assetsTableSettings
  trackingTableSettings
  manageTableSettings
  kitsTableSettings
  warehouseTableSettings
  settings
  permissions
  projectIds
  company {
    id
    name
    subdomain
    settings
    permissions
    fieldSettings
    settingsConfig
    labelsLogoUrl
  }
  accessLink {
    id
  }
`;

export const PAGINATION_FRAGMENT = `
  totalPages
  totalCount
  currentPage
  limitValue
`;

export const ATTACHMENT_FRAGMENT = `
  id
  url
  fileExtension
  fileSize
  fileName
  createdAt
`;

export const KIT_FRAGMENT = `
  id
  assetId
  itemId
  photo
  manufacturer
  description
  dimensions
  color
  model
  images {
    ${KIT_IMAGE_FRAGMENT}
  }
  project {
    id
    name
  }
  client {
    id
    name
  }
`;

export const KIT_ASSET_FRAGMENT = `
  id
  kitAssets {
    id
    quantity
    asset {
      id
      assetId
      photo
      images {
        ${ASSET_IMAGE_FRAGMENT}
      }
      model
      description
      dimensions
      quantity
    }
  }
`;

export const ASSET_FRAGMENT = `
  id
  type
  itemId
  minimumQuantity
  assetId
  projectId
  existingBuilding
  newBuilding
  existingFloor
  newFloor
  existingRoom
  newRoom
  barcode
  quantity
  availableQuantity
  category
  price
  fileUrl
  barcodes {
    id
    code
  }
  description
  descriptionComments
  quantity
  color
  dimensions
  manufacturer
  condition
  finishes
  employeeName
  originComments
  destinationComments
  originEmployeeName
  destinationEmployeeName
  destinationQuantity
  originRoomNumber
  destinationRoomNumber
  destinationRoomNumbers
  disposition
  originCubbyNumber
  notes
  receivedOn
  warehouseLocation
  palletLocation
  vaultLocation
  photo
  quantityOrdered
  quantityReceived
  model
  poNumber
  reservedQuantity
  warranty
  itemCode
  unitCost
  subcategory
  rackLocations
  images {
    ${ASSET_IMAGE_FRAGMENT}
  }
  project {
    id
    name
  }
  client {
    id
    name
  }
  attachments {
    ${ATTACHMENT_FRAGMENT}
  }
  weight
  warehouseQuantity
  receivingReportNumber
  palletType
`;

export const ASSET_MEDICAL_FRAGMENT = `
  departmentName
  tssNumber
  serialNumberUrl
  capitalAssetNumberUrl
  labName
  capitalAssetNumber
  serialNumber
  model
  vendorRequired
  destinationNumber
  installResponsibility
  benchFloorHoodWallBsc
  siesmicAnchorRequired
  volts
  ampsWatts
  btu
  hertz
  phase
  nemaConfig
  plugQuantity
  standbyPower
  normalPower
  dedicatedPower
  ups
  standbyPowerSb
  plugConnection
  hseCo
  localGasTanks
  hseVac
  localVacPump
  icw
  ihw
  pcwSupply
  pcwReturn
  ro
  di
  floorSink
  floorDrain
  solveWasteColl
  exhHeader
  vacPumpVentPlumbing
  exhCeilingBlastGate
  exhSnorkelArmPlumbing
  exhOther
  listExhType
  exhaust
  vacPumpVentMechanical
  exhSnorkelArmMechanical
  cfm
  dataPortQty
  bms
  otherUtility
  moveNotes
  ase
`;

export const PROJECT_FRAGMENT = `
  id
  name
  disabled
  active
  fieldSettings
  clientId
  createdAt
  settings
  storageEnabled
  customerName
  customerOwner
  customerNotes
  contactName
  contactEmail
  contactPhoneNumber
  customerEmailSendList
  osDEmailSendList
  billingEmailSendList
  billingType
  billingMethod
  billingDate
  cwtInRate
  cwtStorageRate
  cwtOutRate
  normalPalletRate
  oversizePalletRate
  minimumCharge
  squareFootRate
  cubicFootRate
  address {
    ${ADDRESS_FRAGMENT}
  }
`;

export const CLIENT_FRAGMENT = `
  id
  name
  disabled
  projects {
    ${PROJECT_FRAGMENT}
  }
`;

export const BARCODE_SCAN_FRAGMENT = `
  id
  projectId
  clientId
  assetId
  barcode
  location
  address
  position
  employeeName
  scanDate
`;

export const FAVORITE_REPORTS_FRAGMENT = `
  id
  projectId
  payload
  createdAt
  project {
    name
  }
  user {
    email
  }
  notifications {
    client { id }
    project { id }
  }
`;

export const MOVE_MATRIX_FRAGMENT = `
  id
  status
  processingLog
  createdAt
  originalFile {
    filename
    downloadUrl
  }
  processedFile {
    filename
    downloadUrl
  }
`;

export const SHOPPING_INVENTORY_ITEM_FRAGMENT = `
  id
  assetId
  quantity
  deliveryDate
  deliveryTime
  building
  floor
  room
  location
  city
  street
  zip
  poNumber
  idsrNumber
  eocNumber
  comment
  status
  position
  pickedStatus
  disposition
  project {
    id
    name
  }
`;

export const SHOPPING_INVENTORY_REQUEST_FRAGMENT = `
  assets {
    ${ASSET_FRAGMENT}
  }
  status
  email
  inventoryItems {
    ${SHOPPING_INVENTORY_ITEM_FRAGMENT}
  }
`;

export const ACCESS_LINK_FRAGMENT = `
  id
  name
  token
  active
  projectIds
  permissions
  createdAt
`;

export const INVENTORY_ITEM_FRAGMENT = `
   id
   pickedStatus
   inventoryRequestId
   assetId
   quantity
   checkedOutQuantity
   checkedInQuantity
   status
   addressLine
   comment
   deliveryDate
   deliveryTime
   building
   floor
   room
   location
   city
   street
   zip
   poNumber
   idsrNumber
   eocNumber
   createdAt
   location
   disposition
   position
   project {
     id
     name
   }
   asset {
     ${ASSET_FRAGMENT}
   }
`;

export const DETAILED_INVENTORY_REQUEST_FRAGMENT = `
  id
  uid
  status
  email
  ccEmails
  reviewStatus
  createdAt
  requestedAt
  autoApproved
  addressLine
  multipleAddress
  workOrderNumber
  poNumber
  requirements
  designer
  startDate
  startTime
  endDate
  endTime
  comment
  resources
  street
  city
  zipCode
  members
  vaStatus
  revisionNumber
  contactName
  contactPhoneNumber
  location
  deliveryDate
  disposition
  user {
    id
    email
    accessLink {
      id
      name
    }
  }
  locationNotes
  inventoryItems {
    ${INVENTORY_ITEM_FRAGMENT}
  }
`;

export const LOCATION_FRAGMENT = `
  id
  name
  address
`;

export const REQUESTOR_FRAGMENT = `
  id
  name
  email
`;

export const WAREHOUSE_LOCATION = `
  id
  locationId
  locationName
  zone
  locationType
  aisle
  rack
  shelf
  slot
  sqFtOfSlot
  occupied
`;

export const WAREHOUSE_LOCATION_WITH_IMAGES = `
  id
  locationId
  locationName
  zone
  locationType
  aisle
  rack
  shelf
  slot
  sqFtOfSlot
  warehouseLocationImages {
    ${WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
  }
  occupied
`;

export const SHIPMENT_FRAGMENT = `
  id
  shipmentId
  bol
  carrierName
  notes
  orderNumber
  totalPieces
  customerPoNumber
  weightUnits
  totalWeight
  vendorName
  trackingNumber
  createdAt
  startTime
  completeTime
  user {
    email
  }
  attachments {
    ${ATTACHMENT_FRAGMENT}
  }
`;

export const SHIPMENT_ITEM_FRAGMENT = `
  id
  assetId
  quantity
  asset {
    ${ASSET_FRAGMENT}
    ${ASSET_MEDICAL_FRAGMENT}
  }
`;
